<template>
    <div class="content-wrapper">
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="content">
            <Markers stage3 :forcedDisabledStages="forcedDisabledMarkersStages"></Markers>
            <component
                @createConnector="createConnector"
                @cancel="cancel"
                @endSettings="goToProject"
                v-if="activeComponent"
                :is="activeComponent"
            ></component>
            <h2 class="dashboard mt-12" v-else>
                В настоящее время настройки в этом источнике не поддерживается
            </h2>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
//REFACTORING: Перенести логику из первичных настроек коннекторов сюда
import { mapState, mapMutations } from 'vuex'
import Markers from '@/components/addConnector/Markers.vue'
import Breadcrumbs from '@/components/Breadcrumbs'
import settingsList from '@/components/connectorSettings/list'
import { CONNECTOR_TYPES } from '../vars/general'
import * as _ from 'lodash'
import cfg from '@root/config'
export default {
    name: 'AddConnectorStage3',
    components: {
        Markers,
        Breadcrumbs,
    },
    data: () => ({
        CONNECTOR_TYPES,
        forcedDisabledMarkersStages: [],
    }),

    computed: {
        ...mapState('project', ['currentAddingConnector', 'loggedAddingConnector']),
        currentAddingConnectorType() {
            return this.loggedAddingConnector.connectorType
        },
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        activeComponent() {
            console.log(settingsList.find(el => el.connectorType === this.currentAddingConnectorType))
            return settingsList.find(el => el.connectorType === this.currentAddingConnectorType)?.component
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: this.project.name,
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'ДОБАВИТЬ ИСТОЧНИК',
                    disabled: true,
                    to: '',
                },
            ]
        },
    },
    methods: {
        cancel() {
            const projectId = this.$router.currentRoute.params.id
            this.$store.dispatch('project/deleteConnector', this.loggedAddingConnector.connectorId)
            this.$router.push(`/project/${projectId}#connectors`)
        },
        goToProject() {
            const projectId = this.$router.currentRoute.params.id
            this.$router.push(`/project/${projectId}`)
        },
        async createConnector({ settings, useRedirect }) {
            if (_.isNil(settings)) settings = {}
            if (_.isNil(useRedirect)) useRedirect = true
            settings.isDraft = false
            const connectorId = this.loggedAddingConnector.connectorId
            this.$store.commit('startLoading')
            await this.$store.dispatch('project/updateConnectorSettings', { connectorId, settings })
            this.$store.commit('endLoading')
            if (useRedirect) this.goToProject()
        },
    },
    created() {
        //Если пользователь попадает на этот экран через завершение подключения, запрещаем ему переходить на 1 и 2 подключения
        if (this.$router.currentRoute.query.complete) {
            this.forcedDisabledMarkersStages = [1, 2]
        }

        this.$on('endSettings', this.goToProject)
        this.$on('createConnector', this.createConnector)
        this.$on('goToProject', this.goToProject)
        this.$on('cancel', this.cancel)
    },
}
</script>
<style lang="sass" scoped>
.addConnector
    padding-top: 5%
    padding-left: 10%
    padding-right: 10%
.cancel-btn
    border-color: #0000001F
</style>

<template>
    <div class="markers">
        <v-row class="ma-0 mb-3">
            <v-col
                class="marker"
                @click="goToPrevStage(1)"
                :class="[disabledStages.includes(1) ? 'disabled' : '']"
            >
                <v-row align="center">
                    <div class="circle" :class="{ 'circle-active': stage1 }">
                        <h2 class="font-weight-regular">1</h2>
                    </div>
                    <h2 class="font-weight-regular ml-4">Выбор источника</h2>
                </v-row>
            </v-col>
            <v-col
                class="marker"
                :class="[disabledStages.includes(2) ? 'disabled' : '']"
                @click="goToPrevStage(2)"
            >
                <v-row align="center">
                    <div class="circle" :class="{ 'circle-active': stage2 }">
                        <h2 class="font-weight-regular">2</h2>
                    </div>
                    <h2 class="font-weight-regular ml-4">Авторизация</h2>
                </v-row>
            </v-col>
            <v-col
                class="marker"
                :class="[disabledStages.includes(3) ? 'disabled' : '']"
                @click="goToPrevStage(3)"
            >
                <v-row align="center">
                    <div class="circle" :class="{ 'circle-active': stage3 }">
                        <h2 class="font-weight-regular">3</h2>
                    </div>
                    <h2 class="font-weight-regular ml-4">Настройки</h2>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Markers',
    components: {},
    props: {
        stage1: {
            type: Boolean,
            default: false,
        },
        stage2: {
            type: Boolean,
            default: false,
        },
        stage3: {
            type: Boolean,
            default: false,
        },
        //Запрещенные стадии
        forcedDisabledStages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        disabledStages() {
            let disabledStages = []
            if (this.stage1) {
                disabledStages = [2, 3]
            } else if (this.stage2) {
                disabledStages = [3]
            } else {
                disabledStages = []
            }
            return disabledStages.concat(this.forcedDisabledStages)
        },
    },
    methods: {
        goToPrevStage(clickStage) {
            if (this.disabledStages.includes(clickStage)) {
                return
            }
            const path = this.$router.currentRoute.path
                .split('/')
                .slice(0, 3)
                .join('/')

            if (this.stage2 && clickStage === 1) {
                this.$router.push(`${path}/addConnectorStage1`)
            }
            if (this.stage3 && clickStage === 2) {
                this.$router.push(`${path}/addConnectorStage2?fromStage=3`)
            }
            if (this.stage3 && clickStage === 1) {
                this.$router.push(`${path}/addConnectorStage1?fromStage=3`)
            }
        },
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
.marker
    cursor: pointer
.circle
    width: 48px !important
    height: 48px !important
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50%
    border: 1px solid #0000001F
.circle-active
    background-color: $main-light
    border: 1px solid $main
.disabled
    opacity: 0.5
    cursor: default !important
</style>
